.hero {
  @include width(60%, auto, 0, 0, 0, 0);
  @include pa_ma(10% 0 0 0, none);
  margin: auto;
  text-align: center;
  position: relative;
  z-index: 5;

  &::after {
    content: "";
    @include positions(absolute, 50px, none, 0, none, 130%, 100vh);
    background-image: url("../components/assets/images/bg-dot.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }

  h1 {
    font-size: $xl-3;
    font-weight: 500;
    line-height: 1;
    span {
      color: $primary;
      font-size: $xl-4;
    }
  }
  p {
    margin: 30px 0 50px 0;
  }

  .search {
    @include width(100%, none, $white, $dark, 40px, 10px);
    @include flex(center, center, center);
    @include shadow(-5px 9px 20px 0px rgb(23 45 89 / 3%));

    hr {
      @include default;
      background: rgba(#000000, 0.2);
      height: $lg;
      width: 0.1rem;
      margin: 5px;
    }
    input {
      width: 80%;
      padding: 10px;
      font-size: $md;
    }
    .heIcon {
      font-size: $xl-2;
    }
  }
}
